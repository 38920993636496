import React from 'react';
import {graphql, navigate} from "gatsby";
import Layout from "../../components/Layout/ru";
import {Button, ButtonGroup, Container} from "@mui/material";
import EnglishBlock from "../../components/EnglishBlock/EnglishBlock";
import Roadmap from "../../components/Roadmap/Roadmap";


function FrontEndRoadmap({data, location}) {

  const roadmapData = data.allMarkdownRemark.group.filter((item) => item.fieldValue === "front")[0]
  const sortedData = !!roadmapData && roadmapData.edges
    .sort((a, b) => {
      const aOrder = a.node.frontmatter.order_fe;
      const bOrder = b.node.frontmatter.order_fe;
      if (aOrder > bOrder) return 1;
      if (aOrder < bOrder) return -1;
      return 0;
    })

  return (
    <Layout
      location={location}
      title='Front End Roadmap'
      description='Здесь я описал порядок изучения технологий для React Front End разработчиков'
    >
      <Container>
        <div style={{textAlign: "center", padding: "15px 0"}}>
          <h1 style={{textTransform: "capitalize"}}>JS  Front End Roadmap {new Date().getFullYear()}</h1>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button
              onClick={() => {navigate("/ru/front-end-roadmap")}}
              style={{backgroundColor: 'black', borderColor: 'white'}}
            >
              Frontend
            </Button>
            <Button
              onClick={() => {navigate("/ru/back-end-roadmap")}}
              style={{backgroundColor: 'black', borderColor: 'white'}}>
              Backend
            </Button>
          </ButtonGroup>
        </div>
        {!!roadmapData && <Roadmap roadmapData={sortedData} />}
        <EnglishBlock/>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "roadmap"}}, fields: {langKey: {eq: "ru"}}}) {
      group(field: frontmatter___category) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              links {
                link {
                  name
                  type
                  url
                }
              }
              order_be
              order_fe
            }
          }
        }
        fieldValue
      }
    }
  }
`

export default FrontEndRoadmap;