import React from 'react';
import * as styles from "./styles.module.css";
import {Button} from "@mui/material";
import {graphql, StaticQuery} from "gatsby";
import {injectIntl} from "react-intl";


const EnglishBlock = (props) => {
	const content = props.intl.messages.englishBlockContent;

	return (
		<StaticQuery
			query={graphql`
					{
						allFile(filter: {extension: {eq: "jpg"}}) {
							edges {
								node {
									relativePath
									childImageSharp {
										gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP)
									}
								}
							}
						}
  				}
				`}
			render={data => {
				const background = data.allFile.edges.filter((item) => item.node.relativePath === "english_background.jpg")[0];

				return(
					<div
						className={styles.EnglishBlock}
						style={{backgroundImage: `url(${background.node.childImageSharp.gatsbyImageData.images.fallback.src})`}}
					>
						<div className={styles.EnglishDarkWrapper}></div>
						<div style={{position: "relative", zIndex: 99, display: "flex", flexDirection: "column"}}>
							<h2>{content.title}</h2>
							<p>{content.text1}</p>
							<p>{content.text2}</p>
							<p>{content.text3}</p>
							<Button
								href='https://preply.com/ru/?pref=NjExOTQwNg==&id=1659819539.832809'
								variant="contained"
								target="_blank"
								style={{ backgroundColor: "#ffe100",	color: "black", margin: "25px 0", width: 250}}
							>
								{content.button}
							</Button>
						</div>
					</div>
				)
			}}
		/>
	)
}

export default injectIntl(EnglishBlock);